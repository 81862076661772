import * as React from 'react';
import _ from 'lodash';
import parseISO from 'date-fns/parseISO';
import dateIsPast from 'date-fns/isPast';
import formatDate from 'date-fns/format';
import { Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import newsAndInfoData from '../../data/about/newsAndInfo.json';
import { BaseLayout, BlogEntry, FlexRow } from '../../components';

const getPostId = ({ postDate, title }: any) => {
  return `${title}_${postDate}`;
};

export const NewsAndInfoPageInternal = ({ newsEntries, signUp }: typeof newsAndInfoData) => {
  const newsEntryModel = _.map(newsEntries, (item: any) => {
    const { postDate, archiveDate, title } = item;
    // eslint-disable-next-line no-nested-ternary
    const parsedPostDate = postDate ? _.isDate(postDate) ? postDate : parseISO(postDate) : null;
    // eslint-disable-next-line no-nested-ternary
    const parsedArchiveDate = archiveDate ? _.isDate(archiveDate) ? archiveDate : parseISO(archiveDate) : null;

    return {
      ...item,
      id: getPostId({ postDate, title }),
      isArchived: parsedArchiveDate && dateIsPast(parsedArchiveDate),
      formattedPostDate: parsedPostDate ? formatDate(parsedPostDate, 'yyyy-MM-dd') : '',
    };
  });

  return (
    <BaseLayout title="News and Info">
      <Container>
        <Row>
          <Col md={9}>
            {_.isEmpty(newsEntryModel) && (
              <>
                <p className="text-center">There a no news items at this moment. Check back later to keep up with the latest AHCU news!</p>
              </>
            )}
            <>
              {
                _.map(newsEntryModel, (newsEntry, i) => {
                  const isLast = i === _.size(newsEntryModel) - 1;

                  return (
                    <React.Fragment key={newsEntry.id}>
                      <BlogEntry {...newsEntry} isActive={!newsEntry.isArchived} />

                      {!isLast && <hr />}
                    </React.Fragment>
                  );
                })
              }
            </>
          </Col>
          <Col className="py-2" md={3}>
            <div className="bg-primary text-white p-3 rounded">
              <h5 className="text-white text-center">{signUp.header}</h5>
              <Form action="https://archerheights.us8.list-manage.com/subscribe/post?u=08557091424463a7dc6aeb995&amp;id=b69b6a7d6c" method="POST" target="_blank">
                <FormGroup>
                  <Label>Email:</Label>
                  <Input name="EMAIL" type="email" required bsSize="sm" />
                </FormGroup>
                <FormGroup>
                  <Label>First Name:</Label>
                  <Input name="FNAME" required bsSize="sm" />
                </FormGroup>
                <FormGroup>
                  <Label>Last Name:</Label>
                  <Input name="LNAME" required bsSize="sm" />
                </FormGroup>
                <FormGroup>
                  <Label>Birthday:</Label>

                  <FlexRow childSpacingX={2}>
                    <div>
                      <Input name="MMERGE6[month]" required placeholder="MM" bsSize="sm" />
                    </div>
                    <div>
                      <Input name="MMERGE6[day]" required placeholder="DD" bsSize="sm" />
                    </div>
                  </FlexRow>
                </FormGroup>

                <Button block color="secondary">Submit</Button>

                <div style={{ position: 'absolute', left: -5000 }} aria-hidden="true">
                  <input
                    type="text"
                    name="b_08557091424463a7dc6aeb995_b69b6a7d6c"
                    tabIndex={-1}
                    value=""
                  />
                </div>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </BaseLayout>
  );
};

export default () => <NewsAndInfoPageInternal {...newsAndInfoData} />;
